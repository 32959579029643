import { AxiosResponse } from "axios";
import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";

export const clientAutocomplete = (
  data: PaginatedRequest
): Promise<
  AxiosResponse<PaginatedResponse<{ name: string; id: number }[]>>
> => {
  return getPaginated("client/autocomplete", data);
};
